@font-face {
    font-family: legalEmirates-Arabic;
    src: url('./AdobeArabicRegular.otf');
  } 
  /* legalEmirates-Arabic */
.arabic-style-font-visitor{
    font-family: legalEmirates-Arabic !important;
    font-size: 35px;
    font-weight: 600;
}
.counsellingForm{
    .formInput{
        margin-bottom: 20px !important;
        input,
        textarea,
        select{
            background: white;
            height: 45px !important;
            padding: 6px 15px;
            color: #666666 !important;
            border-radius: 0;
            font-weight: 550;
            font-size: 16px !important;
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 16px !important;
            }
        }
        
        select{
            color: #666666 !important;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background:  white url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px !important;
            padding: 15px;
        }
        p{
            
            font-size: 14px;
            color: red;
            font-weight: 550;
        }
    }
    .formInput1{
        margin-bottom: 30px;
        input,
        textarea,
        select{
            background: #F3F0EA;
            height: 50px;
            padding: 6px 60px !important;
            color: #666666;
            border-radius: 0;
            
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid rgba(192, 181, 150, .5);
            &:focus{
                -webkit-box-shadow: none;
                box-shadow: none;
                border-color: #c0b596;
            }
            &::-webkit-input-placeholder{
                color: #bbb5a5;
                font-size: 16px;
            }
        }
        
        select{
            color: #666666 !important;
            position: relative;
            -webkit-appearance: none;
            -ms-appearance: none;
            -o-appearance: none;
            appearance: none;
            -moz-appearance: none;
            background: #F3F0EA url(../../images/select-icon.png) no-repeat calc(100% - 15px) center;
        }
        textarea{
            height: 150px;
            padding: 15px;
        }
        p{
       
            font-size: 14px;
            color: red;
            font-weight: 550;
        }
    }
    
    button{
        font-size: 15px;
        font-weight: 700;
        color: #fff;
        padding: 9px 25px;
        border: 2px solid transparent;
        text-transform: capitalize;
        display: inline-block;
        transition: all .3s;
        background: #BE9D40;
        &:hover{
            background:#BE9D40;
        }
    }
}
.react-tel-input .selected-flag{
    width: 50px !important;
}
.react-tel-input .form-control {
    width: 100% !important;
}
.client-file-area-left-side{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.client-file-area-left-side p{
    font-size: 15px;
    font-weight: 550;
    color: #282e3f;
}
.signon-bottom-area1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.client-file-area-left-side1{
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.client-file-area-left-side1 p{
    font-size: 15px;
    font-weight: 550;
    color: #282e3f;
}
/* checkbox */
.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }
  
    // Box.
    & + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 30px;
      background: white;
      border: 1px solid #c0b596;
    }
  
    // Box hover
    &:hover + label:before {
      background: white;
      border: 1px solid #c0b596;
    }
    
    // Box focus
   /*  &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    } */
  
    // Box checked
    &:checked + label:before {
      background: #c0b596;
    }
    
    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      position: absolute;
      left: 9px;
      top: 13px;
      background: white;
      width: 4px;
      height: 4px;
      box-shadow: 
        2px 0 0 white,
        4px 0 0 white,
        4px -2px 0 white,
        4px -4px 0 white,
        4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  
  
  .title {
    text-align: center;
    color: rgb(69, 113, 236);
  }
  .checkbo-label-A{
      color: #282e3f;
      font-size: 16px;
      font-weight: 550;
      padding-right: 10px;
  }
  .clear-button{
   background-color: #c0b596;
   padding: 10px 15px 10px 15px;   
   color: white;
   cursor: pointer;
   border-radius: 5px;
  }
  .customer-file-loader-with-button{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
  }
  .caseFiletopAreaHeaderREceipnest{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 30px;
  }
  /* font-family: 'Open Sans', sans-serif; */
  .caseFiletopAreaHeaderREceipnest h1{
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
  }
 
  @media (max-width:7000px) and (min-width:600px){
    .caseFiletopAreaHeaderREceipnest img{
width: 250px;
    }
  }
  @media (max-width:600px) and (min-width:0px){
    .caseFiletopAreaHeaderREceipnest img{
        width: 200px;
    }
}
.caseFiletopAreaHeaderb{margin-top:-20px!important;margin-bottom:-25px!important;display:flex;justify-content:space-between;align-items:center;flex-wrap:wrap}.caseFiletopAreaHeaderb p{font-size:20px;font-weight:600;color:#282e3f!important;}@media (max-width:7000px) and (min-width:991px){.date-label-deisgin-box{border:1px solid #c0b596;font-size:16px;padding:2px 6px!important}}@media (max-width:991px) and (min-width:768px){.date-label-deisgin-box{border:1px solid #c0b596;font-size:14px;padding:2px 6px!important}}@media (max-width:768px) and (min-width:0px){.date-label-deisgin-box{border:1px solid #c0b596;font-size:14px;padding:2px 6px!important}}
.checkbow-style-areafile{display:flex;justify-content:flex-start}
.receptionist-note-add-file{
    background: white;
   
    padding: 15px;
    color: #666666;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, .5);
    font-weight: 550;
    margin-bottom: 30px;
}
.receptionist-note-add-file p{
    margin-bottom: 0px !important;
}
.receptionist-note-add-customer{
    height: 85%;
    background: white;
    color: #666666;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(192, 181, 150, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.receptionist-note-add-customer p{
    font-size: 16px;
    font-weight: 600;
    color: #666666;
    cursor: pointer;
}
.floatffff{
    z-index: 100000;
	position:fixed;
	width: 140px;
	height:40px;
	bottom:40px;
	left:70px;
	background-color:#353535;
	color:#FFF;
	border-radius:20px;
	text-align:center;
    box-shadow: 2px 2px 3px #999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my-floatffff{
    margin-top:22px;
    font-weight: 600;
    font-size: 18px;
}

.visitor-form-border-box{
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.visitor-form-border-box-left{
    width:70%;
    height: 40px;
    background-color: #BE9D40;
    color: #BE9D40;
}
.visitor-form-border-box-right{
    width:30%;
    height: 40px;
    background-color:#CABC99;
    color: #CABC99;
}
.visitor-form-border-box1{
    height: 20px;
    margin-top: -15px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.visitor-form-border-box-left1{
    width:20%;
    height: 20px;
    background-color: #BE9D40;
    color: #BE9D40;
}
.visitor-form-border-box-right1{
    width:10%;
    height: 20px;
    background-color:#CABC99;
    color: #CABC99;
}
.visitor-form-top-field-text{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.visitor-form-top-field-text span{
    font-family: legalEmirates-Arabic !important;
    font-weight: 600 !important;
    font-size: 18px !important;

}
.office-visit-first-time-arabic{
    font-family: legalEmirates-Arabic !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    text-align: right;
}
.visitor-type-direction{
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    margin-top: -20px;
    margin-bottom: 20px;  
}
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before{
    background-color: #BE9D40 !important;
    
}
.ant-switch-checked {
    background-color: #BE9D40 !important;
}
.visitor-type-direction  p{
    font-size: 15px;
    display: inline;
    font-weight: 600;
    margin-right: 5px;
}
.office-visit-first-time{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.Nationality1-list:focus{
outline: none !important;
}
/* .visitor-type-direction-btn label::before{
    background-color: #BE9D40 !important;
}
.visitor-type-direction-btn label::after{
    background-color: #BE9D40 !important;
} */


.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #302E34;
    width: 40px !important;
    height: 40px !important;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  .loader1188{
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #302E34;
    width: 40px !important;
    height: 40px !important;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
 